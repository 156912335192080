import { Response } from "~/old-app/types/api";
import { Api } from "../api-store";
import {
  Product,
  ProductOption,
  ProductsQueryParams,
  ProductVariant,
} from "./product-types";

export const ProductApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    products: builder.query<
      Response<Product[]>,
      ProductsQueryParams | undefined
    >({
      providesTags: (result, error, params) => [{ type: "products" }],
      query: ({ ...params }) => ({
        url: `/products`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (
        currentCache: Response<Product[]>,
        newItems: Response<Product[]>
      ) => {
        if (
          currentCache.response.data?.length + newItems.response.data?.length <=
          (newItems.response.meta?.total || 0)
        ) {
          currentCache.response.data.push(...newItems.response.data);
        }
        return currentCache as Response<Product[]>;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    similarProducts: builder.query<
      Response<Product[]>,
      ProductsQueryParams | undefined
    >({
      providesTags: (result, error, params) => [{ type: "products", params }],
      query: ({ ...params }) => ({
        url: `/get_similar_products`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (
        currentCache: Response<Product[]>,
        newItems: Response<Product[]>
      ) => {
        if (
          currentCache.response.data?.length + newItems.response.data?.length <=
          (newItems.response.meta?.total || 0)
        ) {
          currentCache.response.data.push(...newItems.response.data);
        }
        return currentCache as Response<Product[]>;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    oldProducts: builder.query<
      Response<Product[]>,
      ProductsQueryParams | undefined
    >({
      providesTags: (result, error, params) => [
        { type: "old-products", params },
      ],
      query: ({ ...params }) => ({
        url: `/get_old_order_products`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (
        currentCache: Response<Product[]>,
        newItems: Response<Product[]>
      ) => {
        if (
          currentCache.response.data?.length + newItems.response.data?.length <=
          (newItems.response.meta?.total || 0)
        ) {
          currentCache.response.data.push(...newItems.response.data);
        }
        return currentCache as Response<Product[]>;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    product: builder.query<Response<Product>, string | undefined>({
      providesTags: (result, error, id) => [{ type: "product", id }],
      query: (id) => ({
        url: `/products/${id}`,
        method: "GET",
      }),
    }),

    listDiscountProducts: builder.query<
      Response<Product[]>,
      ProductsQueryParams | undefined
    >({
      providesTags: ["discount-products"],
      query: ({ ...params }) => ({
        url: `/products/discount`,
        method: "GET",
        params,
      }),
    }),

    productVariants: builder.query<
      Response<ProductVariant[]>,
      string | undefined
    >({
      providesTags: (result, error, id) => [{ type: "product-variant", id }],
      query: (id) => ({
        url: `/products/${id}/get-variants`,
        method: "GET",
      }),
    }),

    productOptions: builder.query<
      Response<ProductOption[]>,
      string | undefined
    >({
      providesTags: (result, error, id) => [{ type: "product-options", id }],
      query: (id) => ({
        url: `/products/${id}/get-options`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useProductsQuery,
  useProductQuery,
  useListDiscountProductsQuery,
  useProductVariantsQuery,
  useProductOptionsQuery,
  useOldProductsQuery,
  useSimilarProductsQuery,
} = ProductApi;
