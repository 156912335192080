import { useState } from "react";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import {
  useListDiscountProductsQuery,
  useOldProductsQuery,
  useProductOptionsQuery,
  useProductQuery,
  useProductsQuery,
  useProductVariantsQuery,
  useSimilarProductsQuery,
} from "./product-api";
import { ProductsQueryParams } from "./product-types";

export const useProducts = (props?: ProductsQueryParams) => {
  const { selectedService, district_id } = useConfigSlice();
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useProductsQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    {
      skip: !selectedService || !district_id,
    }
  );

  const products = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total > products.length;
  const meta = data?.response?.meta;
  const fetchNextPage = () => setPage((page) => page + 1);

  return {
    products,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
    meta,
  };
};
export const useSimilarProducts = (props?: ProductsQueryParams) => {
  const { selectedService, district_id } = useConfigSlice();
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useSimilarProductsQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    {
      skip: !selectedService || !district_id,
    }
  );

  const products = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total > products.length;
  const meta = data?.response?.meta;
  const fetchNextPage = () => setPage((page) => page + 1);

  return {
    products,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
    meta,
  };
};
export const useOldProducts = (props?: ProductsQueryParams) => {
  const { selectedService, district_id } = useConfigSlice();
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useOldProductsQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    {
      skip: !selectedService || !district_id,
    }
  );

  const products = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total > products.length;
  const meta = data?.response?.meta;
  const fetchNextPage = () => setPage((page) => page + 1);

  return {
    products,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
    meta,
  };
};

export const useProduct = (id: string) => {
  const { data, isLoading, isFetching } = useProductQuery(id, { skip: !id });
  return {
    product: data?.response.data,
    isLoading,
    isFetching,
  };
};

export const useDiscountProducts = (props?: ProductsQueryParams) => {
  const { data, isLoading, isFetching } = useListDiscountProductsQuery(props);
  return {
    products: data?.response.data || [],
    isLoading,
    isFetching,
  };
};

export const useProductVariants = (id: string) => {
  const { data, isLoading, isFetching } = useProductVariantsQuery(id, {
    skip: !id,
  });
  return {
    variants: data?.response.data || [],
    isLoading,
    isFetching,
  };
};

export const useProductOptions = (id: string) => {
  const { data, isLoading, isFetching } = useProductOptionsQuery(id, {
    skip: !id,
  });
  return {
    options: data?.response.data || [],
    isLoading,
    isFetching,
  };
};
